import React from 'react';
import Nav from '../components/Nav/Nav';

export default function ContactPage() {
  return (
    <div>
      <Nav />
      <h1>How to get in touch.</h1>
      <p>
        Why not reach out via{' '}
        <a href="https://www.instagram.com/inpprprte/">
          Innprprte Instagram Account
        </a>{' '}
        or find products on <a href="https://squatopia.com">Squatopia</a>
      </p>
    </div>
  );
}
